// SplitIO is a feature flagging library that allows us to control the
// availability of features in our application.
//
// Documentation:
// https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK
//
// Example JS usage:
// client.on(client.Event.SDK_READY, () => {
//   const treatment = client.getTreatment("SPLITIO_TEST");
//   if (treatment === "on") {
//     console.log("SplitIO feature flag is on");
//   } else if (treatment === "off") {
//     console.log("SplitIO feature flag is off");
//   } else {
//     console.log("SplitIO feature flag is not defined");
//   }
// });

const { SplitFactory } = require("@splitsoftware/splitio");

export const SPLITIO_TEST_FLAG = "SPLITIO_TEST";

const factory = SplitFactory({
  core: {
    authorizationKey: window.CONFIGURATION.SPLIT_IO_ID,
    key: window.CONFIGURATION.USER.email_domain,
    trafficType: "email_domain"
  },
  // applies to local development only
  features: {
    [SPLITIO_TEST_FLAG]: "off"
  },
  startup: {
    readyTimeout: 1.5
  }
});

const client = factory.client();

export default client;
