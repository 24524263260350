import * as Sentry from "@sentry/browser";

const environment = window.CONFIGURATION.ENVIRONMENT;
const user = window.CONFIGURATION.USER;

Sentry.init({
  environment,
  dsn: "https://fed67a59da4f45d498c906ce9aedb886@sentry.io/1764096"
});

Sentry.configureScope(scope => {
  scope.setUser({
    email: user.email,
    id: user.id,
    user: user.email,
    username: `${user.first_name} ${user.last_name}`
  });
});
